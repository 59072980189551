// extracted by mini-css-extract-plugin
export var bestPosts = "blog-module--bestPosts--MO8dV";
export var bestPostsList = "blog-module--bestPostsList--QeqF4";
export var bestPostsTitle = "blog-module--bestPostsTitle--dVO5J";
export var blogHeader = "blog-module--blogHeader--Z9CQm";
export var blogMain = "blog-module--blogMain--ig9RU";
export var blogPostsWrapper = "blog-module--blogPostsWrapper--ii58a";
export var blogWrapper = "blog-module--blogWrapper--8SDLF";
export var centralBlogPostWrapper = "blog-module--centralBlogPostWrapper--kbPDg";
export var characterSvg = "blog-module--characterSvg--oUMJ5";
export var hero = "blog-module--hero--41cWI";
export var heroButtons = "blog-module--heroButtons--aGW3I";
export var heroContent = "blog-module--heroContent--qdElG";
export var heroFirstLine = "blog-module--heroFirstLine--VeeyM";
export var heroIllustration = "blog-module--heroIllustration--I7-HM";
export var heroParagraph = "blog-module--heroParagraph--IckPQ";
export var heroSecondLine = "blog-module--heroSecondLine--3Ij8h";
export var heroText = "blog-module--heroText--Wfdfr";
export var mobileHeader = "blog-module--mobileHeader--43xXa";
export var paraText = "blog-module--paraText--ZV31+";
export var purpleBtn = "blog-module--purpleBtn--sNXP-";
export var recentPostList = "blog-module--recentPostList--mZlmD";
export var recentPostsTitle = "blog-module--recentPostsTitle--tm8VM";
export var sectionDetails = "blog-module--sectionDetails--XhW1k";
export var sectionHeader = "blog-module--sectionHeader--Ll4H6";
export var textWrapper = "blog-module--textWrapper--jhrBw";
export var title = "blog-module--title--rVKQl";
export var titleAccent = "blog-module--titleAccent--1Nbyd blog-module--title--rVKQl";
export var titleAccentBlack = "blog-module--titleAccentBlack--SzMjQ blog-module--titleAccent--1Nbyd blog-module--title--rVKQl";
export var titleBold = "blog-module--titleBold--+jlBd blog-module--title--rVKQl";
export var titleSemiBold = "blog-module--titleSemiBold--pmTeY blog-module--title--rVKQl";
export var titleThin = "blog-module--titleThin--V9dil blog-module--title--rVKQl";
export var transparentBtn = "blog-module--transparentBtn--ngboD blog-module--purpleBtn--sNXP-";